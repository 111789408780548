import OurTeam from "../../components/OurTeam/OurTeam"
import SeparatorPage from '../../components/SeparatorPage/SeparatorPage'

function OurTeamPage() {
    return(
        <>
        <OurTeam/>
        <SeparatorPage/>
        </>
    )
}
export default OurTeamPage